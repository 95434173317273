.crossword-board-container {
  position: relative;
  background: #ffffff;
}

.crossword-board {
  z-index: 1;
  background: transparent;
  border: 1px solid #000000;
  display: grid;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}
.crossword-board__item {
  border: 1px solid #000000;
  background: transparent;
  position: relative;
  z-index: 100;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.crossword-board__item--blank {
  background: #000000;
  border: 1px solid #000000;
  outline: 1px solid #000000;
}

.crossword-board--labels {
  position: absolute;
  z-index: 60;
}
.crossword-board__item-label {
  position: relative;
}
.crossword-board__item-label-text {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 14px;
  line-height: 1;
}
.crossword-board--highlight {
  position: absolute;
  z-index: 50;
}
.crossword-board__item-highlight {
  display: grid;
  opacity: 1;
  transition: opacity 0.3s linear;
  box-shadow: 0px 0px 25px 9px rgba(255, 255, 116, 1);
}
