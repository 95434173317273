body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.crossword-board-container {
  position: relative;
  background: #ffffff; }

.crossword-board {
  z-index: 1;
  background: transparent;
  border: 1px solid #000000;
  display: grid;
  list-style-type: none;
  padding: 0;
  margin: 0 auto; }

.crossword-board__item {
  border: 1px solid #000000;
  background: transparent;
  position: relative;
  z-index: 100;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase; }

.crossword-board__item--blank {
  background: #000000;
  border: 1px solid #000000;
  outline: 1px solid #000000; }

.crossword-board--labels {
  position: absolute;
  z-index: 60; }

.crossword-board__item-label {
  position: relative; }

.crossword-board__item-label-text {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 14px;
  line-height: 1; }

.crossword-board--highlight {
  position: absolute;
  z-index: 50; }

.crossword-board__item-highlight {
  display: grid;
  opacity: 1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  box-shadow: 0px 0px 25px 9px #ffff74; }

.tv-layout {
  display: flex;
  font-size: 34px;
}

.tv-left {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.score-header {
  font-size: 27px;
  font-weight: bold;
}

.scoreboard {
  list-style-type: none;
  font-size: 20px;
}

.scoreboard-small {
  list-style-type: none;
  font-size: 16px;
}

.last-clue-header {
  font-weight: bold;
  font-size: 20px;
}

.last-clue {
  font-size: 20px;
}

.tiny-header {
  display: flex;
  margin: auto;
  list-style-type: none;
  font-size: 16px;
  justify-content: center;
}

.current-clue {
  font-size: 44px;
  font-weight: bold;
}

.countdown {
  text-align: left;
  border: 1px black solid;
  padding: 10px;
  width: auto;
  margin: auto;
}

.game-id {
  font-size: 23px;
  margin-top: 45px;
  font-weight: bold;
}

.board-layout {
  flex: 2 1;
}

.leaderboard-list {
  text-align: left;
  margin: auto;
  list-style-type: none;
  font-size: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

