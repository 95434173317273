.tv-layout {
  display: flex;
  font-size: 34px;
}

.tv-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.score-header {
  font-size: 27px;
  font-weight: bold;
}

.scoreboard {
  list-style-type: none;
  font-size: 20px;
}

.scoreboard-small {
  list-style-type: none;
  font-size: 16px;
}

.last-clue-header {
  font-weight: bold;
  font-size: 20px;
}

.last-clue {
  font-size: 20px;
}

.tiny-header {
  display: flex;
  margin: auto;
  list-style-type: none;
  font-size: 16px;
  justify-content: center;
}

.current-clue {
  font-size: 44px;
  font-weight: bold;
}

.countdown {
  text-align: left;
  border: 1px black solid;
  padding: 10px;
  width: auto;
  margin: auto;
}

.game-id {
  font-size: 23px;
  margin-top: 45px;
  font-weight: bold;
}

.board-layout {
  flex: 2;
}

.leaderboard-list {
  text-align: left;
  margin: auto;
  list-style-type: none;
  font-size: 20px;
}
